import shopifyGenericDefaults from '../shopify-generic/config.js';

const onVerifyFitmentResponseReceived = (response) => {
  const relatedProductsEl = window.document.querySelector('#cm-related-products');

  if (relatedProductsEl) {
    relatedProductsEl.classList.toggle('cm_hide', response.Items?.[0]?.fitsTheVehicle !== 'yes');
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    onVerifyFitmentInitResponseReceived: onVerifyFitmentResponseReceived,
    onVerifyFitmentResponseReceived,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchBoxDialogButton', 'RelatedItems'].includes(w.name),
    ),
    {
      name: 'StickyGarage',
      type: 'Garage',
      location: '#cm-sticky-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { selector: '.mobile-search-input', class: 'cm_search-box-root__mobile' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'RelatedItems',
      location: { selector: '#cm-related-products', class: 'cm_hide' },
      template: 'product/relatedItems',
      count: 50,
      useManualItems: true,
    },
  ],
};
