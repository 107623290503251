//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-72:_6720,_9844,_4620,_6096,_492,_5568,_6580,_9812;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-72')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-72', "_6720,_9844,_4620,_6096,_492,_5568,_6580,_9812");
        }
      }catch (ex) {
        console.error(ex);
      }